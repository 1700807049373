<template>
  <div class="outside">
    <rxNavBar v-if="androidFlg == false" title="试岗任务审核" androidOrIOSFlag="true"></rxNavBar>
    <rxNavBar v-else title="试岗任务审核"></rxNavBar>
    <div class="head">
      <div class="search">
        <div class="search-icon" @click="goSearch"></div>
        <input type="text" placeholder="申请人" v-model="searchContent" @keyup.enter="goSearch">
      </div>
      <div class="types">
        <span class="type" @click="isShow = !isShow" id="downList">{{ typeList[number] ? typeList[number].dictionaryTitle : '待提交' }}</span>
        <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
      </div>
      <van-popup v-model="isShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="typeList"
            @cancel="isShow = false"
            :default-index="number"
            value-key="dictionaryTitle"
            @confirm="changeType"
        />
      </van-popup>
    </div>
    <div class="blank-weight"></div>

    <div class="reviews">
      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" >
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="noContent ? '' : '已经到底了'"
            @load="this.onLoad"
        >
          <!--列表-->
          <div class="review" v-for="(item,index) in orders" :key="index">
            <div :class="'top-title top-title-' + item.status"
                >
              {{ item.statusStr }}
            </div>
            <div class="position">{{item.staffName}} - 试岗任务审批</div>
            <div class="details">
              <div class="info">
                <div class="order">部门</div>
                <div class="order-name">{{ item.departmentName }}</div>
              </div>
              <div class="line"></div>
              <div class="info">
                <div class="order">职务</div>
                <div class="order-name">{{ item.dutyName }}</div>
              </div>
            </div>
            <div class="rule3"></div>
<!--            //0待提交，1待上级审， 2待上上级审，3已审批，4拒绝入职，5重新预约考试，6预约待确认，7成绩待上传，8考试未通过，9入职，10重新学习-->
<!--            {{ item.approvalNodeStr }}-->
            <div class="bottom">
<!--              <div class="time">{{ item.addTime }}</div>-->
              <div v-if="checkAuthList(authButtonsList,'superiorApproval')&&item.buttonFlag===1" class="check" @click="approve(item,'approve')">审批</div>
              <div v-else-if="checkAuthList(authButtonsList,'superiorApproval2')&&item.buttonFlag===1" class="check" @click="approve(item,'approve')">审批</div>
              <div v-else-if="checkAuthList(authButtonsList,'trainingManagerApproval')&&item.buttonFlag===1" class="check" @click="approve(item,'approve')">审批</div>
              <div v-else-if="checkAuthList(authButtonsList,'confirmAppointment')&&item.status==='6'" class="check" @click="comfirmAppointment(item)">确认预约</div>
              <div v-else-if="checkAuthList(authButtonsList,'resultUpload')&&item.status==='7'" class="check" @click="resultUpload(item,'resultUpload')">上传考试结果</div>
              <div v-else-if="checkAuthList(authButtonsList,'resultUpload')&&item.status==='11'" class="check" @click="resultUpload(item,'confirmEntry')">确认开单入职</div>
              <div :class="'look'" @click="approve(item,'detail')">查看</div>
            </div>


          </div>
          <common-empty v-if="noContent"></common-empty>
        </van-list>
      </van-pull-refresh>

      <van-popup v-model="comfirmAppointmentShow" position="bottom" :style="{ height: '50%' }" >
        <div class="date-dropDownList">
          <div class="appointmentTime">预约时间：<span>{{itemObj.appointmentTime ? itemObj.appointmentTime : '暂无'}}</span></div>
          <div class="normalBlock">
            <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
            <span class="blockTitle">预约结果</span>
            <div class="rightText">
              <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
              <span class="content-divide"> | </span>
              <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
            </div>
          </div>
          <div class="approveOpinion">
            <p class="yijian" style="font-size: 15px;font-weight: bold">审核意见</p>
            <div class="text_body">
              <van-field v-model="reason"  type="textarea" class="mes_body" rows="3"
                         autosize/>
            </div>
          </div>
          <div class="sub_btn">
            <van-button  :class="'saveButton_Enable'" size="large" @click="saveAppointmentTime()">保存
            </van-button>
          </div>
        </div>
      </van-popup>

      <van-popup v-model="resultUploadShow" position="bottom" :style="{ height: '50%' }" >
        <div class="date-dropDownList">
          <div class="part-inputpart-row" v-if="itemObj.uploadResultType=='confirmEntry'">
            <span class="maker orangeColor">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">开单时间</span>
            <span class="content-divide">|</span>
            <input v-model="itemObj.orderPlacedTime" placeholder="开单时间" disabled style="background-color: white;width: 6.7rem;">
            <!--                  <span class="part-inputpart-row-right"></span>-->
          </div>
          <div class="part-inputpart-row" @click="isCleanTypeShow = !isCleanTypeShow">
            <span class="maker" :class="{'orangeColor':(resultUploadObj.statusStr!='')}">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">考试结果</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="resultUploadObj.statusStr ? 'part-inputpart-row-normaltext':'' ">{{resultUploadObj.statusStr}}</span>
            <img class="part-inputpart-row-right" :class=" isCleanTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <van-popup v-model="isCleanTypeShow" position="bottom">
            <van-picker
                show-toolbar
                :columns="approveResultList"
                @cancel="isCleanTypeShow = false"
                :default-index="resultUploadObj.dictionaryIndex!=''?Number(resultUploadObj.dictionaryIndex):0"
                value-key="dictionaryTitle"
                @confirm="selectResultStatusStr"
            />
          </van-popup>
          <div class="part-inputpart-row" v-if="itemObj.uploadResultType=='resultUpload'">
            <span class="maker" :class="{'orangeColor':(score!='')}">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">考试分数</span>
            <span class="content-divide">|</span>
            <input type="number" v-model="score" placeholder="输入考试分数" style="background-color: white;width: 6.7rem;">
            <!--                  <span class="part-inputpart-row-right"></span>-->
          </div>
          <div class="approveOpinion" v-if="resultUploadObj.status==='5'">
            <p class="yijian" style="font-size: 15px;font-weight: bold">拒绝原因(必填)</p>
            <div class="text_body">
              <van-field v-model="remarks"  type="textarea" class="mes_body" rows="3"
                         autosize/>
            </div>
          </div>
          <div class="approveOpinion2" v-if="itemObj.uploadResultType=='resultUpload'">
            <p class="yijian" style="font-size: 15px;font-weight: bold">附件图片</p>
            <van-uploader v-model="resultUploadObj.picList" multiple
                          upload-icon="plus"
                          accept="image/*"
                          :max-count="50"
                          :after-read="afterReadTest"
                          image-fit="contain"

            >
            </van-uploader>
            <div class="remarks" style="text-align: right;font-size: 14px">注：同意入职必须上传附件</div>
          </div>
          <div class="sub_btn">
            <van-button  :class="!checkResultUpload()?'saveButton_Enable':'saveButton_Disable'" :disabled="!checkResultUpload()?false:true" size="large" @click="saveResultUpload()">保存
            </van-button>
          </div>

        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {checkAuthList, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList,
  queryBaseData,
  approvalTrialPlanList,
  saveOrUpdateAppointQuiz,
  base64Upload
} from "../../../getData/getData";
import {
  Button,
  Divider,
  DropdownItem,
  DropdownMenu,
  Field,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search, Sticky, Uploader
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
  name: "probationPlanApproval",
  components:{
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]:PullRefresh,
    [Field.name]: Field,
    [Uploader.name]: Uploader,//上传图片参数
    [Sticky.name]: Sticky,
  },
  data(){
    return{
      androidFlg:'',
      searchContent:'',
      typeList:[
        {
          dictionaryValue: '0',
          dictionaryTitle: '待提交',
        },
        {
          dictionaryValue: '1',
          dictionaryTitle: '待审批',
        },
        {
          dictionaryValue: '2',
          dictionaryTitle: '已审批',
        },
        {
          dictionaryValue: '-1',
          dictionaryTitle: '全部审批',
        },
      ],
      number:'1',
      isShow:false,
      noContent: false,
      refreshing:false,
      flag: false,
      loading: false,
      finished: false,
      count: 0,
      page: 1,
      orders:[],
      authButtonsList:[],
      comfirmAppointmentShow:false,
      resultUploadShow:false,
      isPass:true,
      isReject:false,
      reason:'',
      score:'',
      remarks:'',
      itemObj:'',
      notClickable:false,  //防连点
      isCleanTypeShow:false,
      resultUploadObj:{
        status:'',
        statusStr:'',
        dictionaryIndex:'',
        attachmentList:[],
      },
      approveResultList:[
        {
          dictionaryIndex:'0',
          dictionaryValue:'2',
          dictionaryTitle:'同意入职'
        },
        {
          dictionaryIndex:'1',
          dictionaryValue:'3',
          dictionaryTitle:'重新考试'
        },
        {
          dictionaryIndex:'2',
          dictionaryValue:'5',
          dictionaryTitle:'拒绝入职'
        },
      ],
    }
  },
  created() {

  },
  activated() {

    // this.number=1
    let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
    if(currentLabel != null){
      if(currentLabel != '' ){
        this.number = Number(currentLabel);
      }
    }
    this.page = 1
    this.orders = []
    this.onLoad();
    this.getStaffFuntionModelList()
    // this.orders = [];
    // this.page = 1;
    // this.finished = false;
    // this.isShow = false
    // this.loading = true;
    // this.noContent = false
    // console.log(1)
    // this.onLoad();
  },
  beforeRouteLeave(to, from, next){
    // if(to.query.enterType == 'detail'){
    //   to.meta.keepAlive = true;
    // }else{
    //   to.meta.keepAlive = false;
    // }
    next()
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null || from.name == 'staffMore'){
      localStorage.removeItem('currentLabel');
    }
    next()
  },
  mounted() {
    this.initChooseTypes()
    this.getStaffFuntionModelList()

  },
  methods:{
    //搜索框查询
    goSearch() {
      this.changeType('',this.number);
    },
    //更改选择类型
    changeType: function (value,index) {
      //将选择的类型放入本地内存中，
      if(value.dictionaryValue==='-1'&&this.searchContent===''){
        responseUtil.alertMsg(this,'必须填写申请人才可搜索全部审批')
        return
      }
      if (index == '') {
        index = 0;
      }
      localStorage.setItem('currentLabel', JSON.stringify(index))
      this.orders = [];
      this.page = 1;
      this.finished = false;
      this.isShow = false
      this.number = index;
      this.loading = true;
      this.noContent = false
      this.onLoad();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orders = []
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    onLoad: function () {
      let that = this
      let initData = {}
      console.log(that.number)
      initData.appStatus = that.number===4?'':that.number
      //debugger
      initData.currentPage = that.page + ''
      initData.numberPage=10
      initData.staff_id = getStaffId()
      initData.searchContent = that.searchContent
      approvalTrialPlanList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount
          // console.log(response.data.data)
          let list = response.data.data.data?response.data.data.data:[]
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i])
          }
          // 加载状态结束
          that.loading = false;
          if(that.refreshing){
            that.refreshing = false
          }
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
          }
          if (1 == that.page && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
          }

          that.page++;
        })
      })
    },

    //审批
    approve(item,enterType){
      this.$router.push({
        name:'probationPlanApprove',
        query:{
          id:item.id,
          enterType:enterType,
          seniorStaffName:item.seniorStaffName,
          superiorStaffName:item.superiorStaffName,
          status:item.status,

        }
      });
    },
    //确认考试时间
    comfirmAppointment(item){
      this.isPass = true;
      this.isReject = false;
      this.itemObj = item
      this.comfirmAppointmentShow = true
    },
    choosePass() {
      this.isPass = true;
      this.isReject = false;
    },
    chooseReject() {
      this.isReject = true;
      this.isPass = false;
    },
    saveAppointmentTime(){
      if(this.notClickable){
        return
      }
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.mainTrialplanRecord_id = this.itemObj.id
      initData.reason = this.reason
      initData.appointmentTime = new Date(this.itemObj.appointmentTime)
      initData.probationQuiz_id = this.itemObj.probationQuiz_id
      // 0已预约1已确认2考试通过3考试不通过的重新预约4预约驳回
      initData.status = this.isPass?'1':'4'
      this.notClickable = true
      saveOrUpdateAppointQuiz(initData).then(function (response) {
        that.notClickable = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertOK(that,'预约成功')
          that.comfirmAppointmentShow = false
          that.orders = [];
          that.page = 1;
          that.finished = false;
          that.isShow = false
          // if (localStorage.getItem('currentLabel') == '') {
          //   that.number = 1;
          // }else{
          //   that.number = localStorage.getItem('currentLabel');
          // }
          that.loading = true;
          that.noContent = false
          that.onLoad();
        })
      })
    },
    //上传考试结果
    resultUpload(item,uploadResultType){
// 0已预约  1已确认  2考试通过  3重新预约  4预约驳回
      this.score = ''
      this.remarks = ''
      this.resultUploadObj = this.$options.data().resultUploadObj
      item.uploadResultType = uploadResultType
      this.itemObj = item
      this.resultUploadShow = true
    },
    selectResultStatusStr(item,index){
      this.isCleanTypeShow = false
      this.resultUploadObj.status= item.dictionaryValue
      this.resultUploadObj.statusStr= item.dictionaryTitle
      this.resultUploadObj.dictionaryIndex= item.dictionaryIndex
    },

    saveResultUpload(){
      if(this.checkResultUpload()){
        if(this.checkResultUpload()===1){
          responseUtil.alertMsg(this,'拒绝入职请填写原因')
        }
        if(this.checkResultUpload()===2){
          responseUtil.alertMsg(this,'请完善所有必填项')
        }
        if(this.checkResultUpload()===3){
          responseUtil.alertMsg(this,'同意入职请上传考试结果附件')
        }
        if(this.checkResultUpload()===4){
          responseUtil.alertMsg(this,'请选择考试结果')
        }
        return
      }
      if(this.notClickable){
        return
      }
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.mainTrialplanRecord_id = this.itemObj.id
      initData.appointmentTime = this.itemObj.uploadResultType=='resultUpload'?new Date(this.itemObj.appointmentTime):new Date(this.itemObj.orderPlacedTime)
      initData.probationQuiz_id = this.itemObj.probationQuiz_id
      // 0已预约1已确认2考试通过3考试不通过的重新预约4预约驳回5拒绝入职
      initData.status = this.resultUploadObj.status
      initData.score = this.score
      initData.remarks = this.remarks
      initData.picList = this.resultUploadObj.picList
      this.notClickable = true
      saveOrUpdateAppointQuiz(initData).then(function (response) {
        that.notClickable = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertOK(that)
          that.resultUploadShow = false
          that.orders = [];
          that.page = 1;
          that.finished = false;
          that.isShow = false
          // if (localStorage.getItem('currentLabel') == '') {
          //   that.number = 1;
          // }else{
          //   that.number = localStorage.getItem('currentLabel');
          // }
          that.loading = true;
          that.noContent = false
          that.onLoad();
        })
      })
    },
    checkResultUpload(){  //true不合格 false合格
      if(this.resultUploadObj.status==''){
        return 4   //没选结果
      }else{  //选择结果
        if(this.itemObj.uploadResultType=='resultUpload'){   //上传成绩
          if(this.score!=''){  //必须选择分数
            if(this.resultUploadObj.status==='5'&&this.remarks===''){  //结果是自离的时候必须写原因
              return 1   //上传成绩、有分数、但是自离没写原因
            }
            if(this.resultUploadObj.status==='2' && (this.resultUploadObj.picList==undefined || this.resultUploadObj.picList.length==0)){  //结果是通过时必须上传图片
              return 3   //上传成绩、有分数、但是通过没传文件
            }
            return false
          }
          return 2   //上传成绩、没分数
        }
      }
      return false
      // if(this.itemObj.uploadResultType=='resultUpload'){
      //   if(this.score!=''&&this.resultUploadObj.status!=''){  //必须选择结果和分数
      //     if(this.resultUploadObj.status==='5'&&this.remarks===''){  //结果是自离的时候必须写原因
      //       return 1
      //     }
      //     if(this.resultUploadObj.status==='2' && (this.resultUploadObj.picList==undefined || this.resultUploadObj.picList.length==0)){  //结果是通过时必须上传图片
      //       return 3
      //     }
      //     return false
      //   }
      //   return 2
      // }else{
      //   return false
      // }

    },


    //物品配置图片多选上传
    afterReadTest(file){
      // this.loadingFlag = true;
      let fileLength = parseInt(file.length) - parseInt(1)
      if(file.length > 1){
        for(let i = 0; i<file.length;i++){
          let fileDetail = file[i];

          this.afterRead(fileDetail,i,fileLength);
        }
      }else{
        this.afterRead(file);
      }
    },

    afterRead(file,i,fileLength) {
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      base64Upload(initData).then(function (response) {
        that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          if(i == fileLength){
            that.loadingFlag = false
          }
        })
      })
    },
    //获取权限方法
    getStaffFuntionModelList() {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'probationPlanApproval'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
          //console.log(that.authButtonsList)
        })
      })
    },
    checkAuthList,
    //初始化选择类型
    initChooseTypes: function () {
      let that = this
      let initData = {}
      initData.dbName = ['description']
      initData.fdName = ['MAINTRIALPLANRECORDSTATUS']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response.data.data.MAINTRIALPLANRECORDSTATUS)
          let list = response.data.data.MAINTRIALPLANRECORDSTATUS
          let object = {
            dictionaryName: "all",
            dictionaryTitle: "全部审批",
            dictionaryValue: "-1",
          }
          list.splice(0, 0, object)

          for (let i = 0; i < list.length; i++) {
            // that.typeList.push(list[i])
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}
.search {
  margin: 10px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}
.types {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
}

.type {
  text-align: left;
  color: #fe5e3a;
  margin-left: 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}
.small-icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.selectList {
  width: 100%;
  padding: 5px 0 14px 0;
  background-color: white;
}
.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}
.h-line {
  height: 1px;
  margin: 0 5px 0 10px;
  background-color: white;
}

.blank-weight {
  height: 93px;
}

.reviews {
  overflow: hidden;
  position: relative;
}
.review {
  margin: 0 15px 15px 15px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.top-title{
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  color: white;
}
//0待提交，1待上级审， 2待上上级审，3已审批，4拒绝入职，5重新预约考试，6预约待确认，7成绩待上传，8考试未通过，9入职，10重新学习
.top-title-0{
  background: linear-gradient(to right, #7cc7ff, #3596fd);
}
.top-title-1{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-2{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-3{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-4,.top-title-10{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}
.top-title-5{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-6{
  background: linear-gradient(to right, #05C7CA, #00B0B6);
}
.top-title-7,.top-title-11{
  background: linear-gradient(to right, #990066, #ff66ff);
}
.top-title-8{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-9{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}

.position {
  margin: 15px 15px 0 15px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: black;
  font-weight: bold;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
}
.details {
  margin-top: 10px;
  width: 100%;
  height: auto;
  //border-bottom: 1px solid #f4f4f4;
  display: flex;
}

.details .info {
  width: 49%;
  height: auto;
  text-align: center;
  margin-top: 10px;
}

.details .info .order {
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.details .info .order-name {
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #fe5e3a;
  margin-top: 10px;
  margin-bottom: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

.details .line {
  width: 1px;
  height: 40px;
  background-color: #f4f4f4;
  margin-top: 18px;
}
/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}

/*    下部按钮*/
.bottom{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.3rem 0;
}

.check,.look{
  /*float: right;*/
  //margin-top: 0.2rem;
  margin-right: 0.5rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*按钮*/
.check{
  background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
  color: white;
}
.look{
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white,white),
  linear-gradient(to right ,#FFC274,#FF5D3B);
  background-clip: padding-box,border-box;
  background-origin: border-box;
  color: #FF5D3B;
}




//预约时间
.appointmentTime{
  font-size: 0.4rem;
  font-weight: bold;
  height: 50px;
  margin: 10px auto 10px 1rem;
  display: flex;
  align-items: center;
  span{
    color: #ff5809;
  }
}
.date-dropDownList{
  padding: 0.3rem;
  height: 92%;
}
.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 0px auto;
  align-items: center;
  .maker {
    color: #999999;
    padding-left: 14px;
    padding-right: 8px;
    font-size: 28px;
  }
  .orangeColor{
    color: rgba(255, 93, 59, 1);
  }
  .choosedStyle{
    color: rgba(255, 93, 59, 1);
    font-weight: bold;
  }
  .blockTitle {
    overflow: hidden;
    font-size: 15px;
    font-weight: bold;
    width: 27%;
  }
  .rightText {
    margin-left: auto;
    font-size: 14px;
    color: #999999;
    .passText {
      flex: 1;
      padding: 0px 20px;
    }
    .content-divide {
      font-size: 14px;
      color: #efefef;
      margin: 0 10px;
    }
    .rejectText {
      padding: 0px 35px 0px 20px;

    }
  }
}

.approveOpinion{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.2rem;
  .yijian{
    font-size: 15px;
  }
  .text_body {
    //padding: 15px;
    margin-top: 0.4rem;
    .mes_body {
      border-radius: 8px;
      padding: 0.2rem;
      background-color: rgb(250, 250, 250);
    }
  }
}
.approveOpinion2{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.2rem;
  min-height: 58%;
  .yijian{
    font-size: 15px;
  }
}
.sub_btn {
  //margin: 0.8rem auto 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.6rem;
  margin-top: 1rem;
  //position: absolute;
  //bottom: 1rem;
  //width: 94%;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  //margin: 0.1rem 0 0.3rem;

}
.saveButton_Disable{
  display: block;
  background-color: rgba(184, 184, 184, 0.2);
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem;
}
//考试结果
/*选择名称的div*/
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 0px auto;
  align-items: center;
  /*圆点样式---灰色*/
  .maker {
    color: #999999;
    padding-left: 14px;
    padding-right: 8px;
    font-size: 28px;
  }
  .orangeColor{
    color: rgba(255, 93, 59, 1);
  }
  .part-inputpart-row-header {
    font-weight: bold;
    font-size: 15px;
    width: 3rem;
  }
  /*竖线样式*/
  .content-divide {
    font-size: 14px;
    color: #efefef;
    margin: 0 5px 0 5px;
  }
  .part-inputpart-row-graytext {
    color: #d9d9d9;
    width: 6rem;
    font-size: 14px;
    padding-left: 0.3rem;
  }
  .part-inputpart-row-normaltext{
    color: black;
  }
  .part-inputpart-row-right-downArrow{
    width: 9px;
    height: 9px;
    margin-right: 15px;
  }
  /*选择三角图标的向上向下样式*/
  .part-inputpart-row-right-upArrow{
    width: 9px;
    height: 9px;
    /*transform: rotateX(180deg);*/
    margin-right: 15px;
  }
}
</style>